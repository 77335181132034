import React, { useEffect, FC, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Globle } from "hooks/Globle";
import Cookies from "js-cookie";
import $ from "jquery";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import Textarea from "shared/Textarea/Textarea";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Banner from "components/SectionHero/Banner";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import FormItem from "components/FormItem";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import axios from "axios";
import Checkbox from "shared/Checkbox/Checkbox";

interface DataList {
  collection: object,
  collection_date: string,
  collection_postcode: string,
  delivery_date: string,
  delivery_postcode: string,
  discount_price: number,
  delivery_services: any,
  price: number,
  optionals: any,
  product: object,
  slot: string,
  vat: number,
  total: number,
}
interface SettingList {
  collection_service_type: any,
  commission: any,
  delivery_service_type: any,
  discount: any,
  unique_postcode: any,
}

const CheckOutAddress: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [collectionAddress, setCollectionAddress] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [data, setData] = useState<DataList>();
    const [pallet, setPallet] = useState([]);
    const [setting, setSetting] = useState<SettingList | any>();
    const [open, setOpen] = useState(false)
    const [billingAsCustom, setBillingAsCustom] = useState(false)
    const [proc, setProc] = useState(false);
    
    const { apiPath, convertToObject } = Globle();
    const userSession = Cookies.get("userSession");

    const loadCheckoutSession = () => {
        axios
            .get(apiPath + "api/checkout-address?sessionId=" + userSession)
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        data: any;
                        success: boolean;
                        pallets: [],
                        setting: {},
                        collectAddress: [],
                        deliveryAddress: [],
                    };

                    if (
                        dataResp.success == false &&
                        typeof dataResp.errors != undefined
                    ) {
                        navigate("/");
                    }


                    if (dataResp.success == true) {
                        console.log(dataResp);
                        setData(dataResp.data);
                        setPallet(dataResp.pallets);
                        setSetting(dataResp.setting);
                        setCollectionAddress(dataResp.collectAddress);
                        setDeliveryAddress(dataResp.deliveryAddress);
                    }
                } else {
                    toast.error("Something went wrong, Please Try Again!");
                }
            })
            .catch((error) => console.error("Error adding user:", error));
    };

    const fetchSessionData = async () => {
      try {
        const response:any = await axios.get(apiPath+'api/session');
        Cookies.set('userSession', response.data);
      } catch (err) {
        toast.error("Something went wrong, Please Try Again!");
      } finally {
  
      }
    };

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const userBookingCheckoutSessionId = params.get('session_id');
      const mode:any = params.get('mode');
      
      
      if (userBookingCheckoutSessionId) {
        setProc(true);
        confirmBooking(userBookingCheckoutSessionId, mode);
      }
    }, [location.search]);

    const confirmBooking = (userBookingCheckoutSessionId:string, mode:string) => {
      console.log(mode);
      axios
      .post(apiPath + "api/confirm-booking?session_id=" + userBookingCheckoutSessionId + '&mode='+mode)
      .then((response) => {
          if (response.status == 200) {
              const dataResp = response.data as {
                  errors: any;
                  error: any;
                  order_id: any;
                  success: boolean;
              };

              if (dataResp.success == false && typeof dataResp.errors != undefined){
                  // navigate("/");
              }

              if (dataResp.success == true) {
                Cookies.set('userBookingOrderId', dataResp.order_id);
                Cookies.set('userBookingCheckoutSessionId', userBookingCheckoutSessionId);
                fetchSessionData();
                navigate("/thankyou");
              }

          } else {
              toast.error("Something went wrong, Please Try Again!");
          }
      })
      .catch((error) => console.error("Error adding user:", error));
    };

    const addressDistriut = (event:any) => {
        var type = $(event.target).attr('data-type');
        var val = $(event.target).val();
        if(val!='' && val!=null){
          var v = val.split(', ');
          $('[name="'+type+'[address_line_1]"]').val((v[0]!=undefined)?v[0]:null).trigger('change');
          $('[name="'+type+'[address_line_2]"]').val((v[1]!=undefined)?v[1]:null).trigger('change');
          $('[name="'+type+'[address_line_3]"]').val((v[2]!=undefined)?v[2]:null).trigger('change');
          $('[name="'+type+'[town]"]').val((v[5]!=undefined)?v[5]:null).trigger('change');
          $('[name="'+type+'[county]"]').val((v[6]!=undefined)?v[6]:null).trigger('change');
        }
    }

    const numberCheck = (event : any) => {
      var val:any = $(event.target).val();
			var valid:any = $(event.target).attr('data-valid');
			if(val.length > valid){
				$(event.target).val(val.slice(0,valid));
				return false;
			}
    }

    const handleErrors = (errors: { [key: string]: string[] }) => {
        Object.keys(errors).forEach(key => {
            const errorMessages = errors[key];
            errorMessages.forEach(message => {
            toast.error(message);
            });
        });
    };

    const handleSubmit = async (event:any) => {
        event.preventDefault();

        $(event.target).find('input[name="sessionId"]').val(userSession ?? '');
        const inputArray = $(event.target).serializeArray();
        const data = convertToObject(inputArray);

        var btn = $(event.target).find('[type="submit"]').html();
        $(event.target).find('[type="submit"]').attr('disabled', 'disabled');
        $(event.target).find('[type="submit"]').html('Redirecting...');
    
        await axios.post(apiPath+'api/store-submittion', data).then(response => {
    
          if(response.status == 200){
            const dataResp = response.data as {errors: any;success: boolean, error:any, redirect:any, order_id:any};

            // console.log(dataResp);
            
            if(dataResp.success == false && typeof dataResp.errors != undefined && Array.isArray(dataResp.errors)){
                dataResp.errors.map((item: string) => toast.error(item));
            }else if(dataResp.success == false && typeof dataResp.errors != undefined){
                handleErrors(dataResp.errors);
            }else if(dataResp.success == false && dataResp.error){
                toast.error(dataResp.error);
            }
    
            if(dataResp.success == true){
                Cookies.set('userBookingRedirectLink', dataResp.order_id);
                window.location.href = dataResp.redirect;
            }else{
              $(event.target).find('[type="submit"]').html(btn);
              $(event.target).find('[type="submit"]').removeAttr('disabled');
            }
            
            
          }else{
            $(event.target).find('[type="submit"]').html(btn);
            toast.error("Something went wrong, Please Try Again!");
          }
    
        }).catch(error => {
          console.error('Error adding user:', error)
          $(event.target).find('[type="submit"]').html(btn);
          $(event.target).find('[type="submit"]').removeAttr('disabled');
        });
    }

    useEffect(() => {
        loadCheckoutSession();

        $(document).on('change keyup', '[required]', function(){
          var _this = $(this);
          if(_this.val() == '' || _this.val() == null){
            $(_this).addClass('isReq');
          }else{
            $(_this).removeClass('isReq');
          }
        })
    }, []);

    return (
        <>
            <style>
              {`
                [required].isReq {
                    animation-name: required;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                    box-shadow: 0 0 2px 1px #ff4b0054;
                    border:solid 1px #ff4b00a8;
                    margin-bottom:10px;
                    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                }
                @keyframes required {
                    0%   {box-shadow:0 0 0px 0px #ff4b0054;}
                    50%  {box-shadow:0 0 4px 1px #ff4b0054;}
                    100%  {box-shadow:0 0 0px 0px #ff4b0054;}
                }
              `}
            </style>
            <div
                className={`nc-PageAbout overflow-hidden relative `}
                data-nc-id="CheckOut"
            >
                <Helmet>
                    <title>Address | PalletBooking</title>
                </Helmet>

                {/* ======== BG GLASS ======== */}
                {/* <BgGlassmorphism /> */}

                <div className="container mt-16">
                    {(!proc) ? (
                      <Banner
                        heading="Address"
                        btnText=""
                        textClass="text-center"
                      />
                    ) : (
                      <Banner
                        heading="Payment Processing..."
                        btnText=""
                        textClass="text-center"
                      />
                      
                    )}
                </div>
            </div>

            <div className="ListingDetailPage">
                <div className="container ListingDetailPage__content">
                    <div className="nc-ListingStayDetailPage">
                        <main className=" z-10 mt-11">

                          <form onSubmit={handleSubmit} method="POST">
                            <input type="hidden" name="sessionId" />

                            {(!proc) ? (
                              <>
                                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-12">
                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 border-b py-3 border-t mb-5">
                                            Goods Description
                                        </h2>

                                        <FormItem label="Please let us know what you are sending *">
                                            <Textarea
                                                name="description"
                                                rows={3}
                                                required={true}
                                                className="isReq"
                                            ></Textarea>
                                        </FormItem>
                                    </div>

                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 border-b py-3 border-t mb-5">
                                            Email Address
                                        </h2>
                                        <FormItem
                                            label="Email *"
                                            desc="Email address used for invoices and labels."
                                        >
                                            <Input name="email" placeholder="xyz@gmail.com" type="email" className="isReq" required={true} />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-12">
                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">
                                            Collection
                                        </h2>

                                        <FormItem label="Please select your collection address">
                                            <Select onChange={addressDistriut} className={`addressDistriut`} name="collect[address]" data-type="collect">
                                              <option value="">Select Address</option>
                                                {collectionAddress && collectionAddress.length > 0 ? collectionAddress.map(
                                                    (item: any, index) => (
                                                        <option key={1000 +index} value={item}> {item} </option>
                                                    )
                                                ) : ""}
                                            </Select>
                                        </FormItem>
                                        
                                        <FormItem
                                          className="mt-4"
                                            label="Collect From *"
                                            desc="Enter company name if commercial"
                                        >
                                            <Input className="mt-1 isReq" type="text" name="collect[address_line_1]" placeholder="Company Name or Adress line 1 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="collect[address_line_2]" placeholder="Adress line 2 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="collect[address_line_3]" placeholder="Adress line 3 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="collect[town]"  placeholder="City/town *"required={true} />
                                            <Input className="mt-1 isReq" type="text" name="collect[county]"  placeholder="County *"required={true} />
                                        </FormItem>

                                        <FormItem
                                          className="mt-4"
                                            label="Postcode"
                                        >
                                          <Input className="bg-gray-100" name="collect[postcode]" value={(data && data.collection_postcode) ? data.collection_postcode : ''} readOnly={true} />
                                        </FormItem>
                                    </div>

                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">
                                            Delivery
                                        </h2>

                                        <FormItem label="Please select your delivery address">
                                            <Select onChange={addressDistriut} name="delivery[address]" data-type="delivery">
                                              <option value="">Select Address</option>
                                                {deliveryAddress && deliveryAddress.length > 0 ? deliveryAddress.map(
                                                    (item: any, index) => (
                                                        <option key={index} value={item}> {item} </option>
                                                    )
                                                ) : ""}
                                            </Select>
                                        </FormItem>
                                        
                                        <FormItem
                                          className="mt-4"
                                            label="Deliver To *"
                                            desc="Enter company name if commercial"
                                        >
                                            <Input className="mt-1 isReq" type="text" name="delivery[address_line_1]" placeholder="Company Name or Adress line 1 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="delivery[address_line_2]" placeholder="Adress line 2 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="delivery[address_line_3]" placeholder="Adress line 3 *" required={true} />
                                            <Input className="mt-1 isReq" type="text" name="delivery[town]"  placeholder="City/town *"required={true} />
                                            <Input className="mt-1 isReq" type="text" name="delivery[county]"  placeholder="County *"required={true} />
                                        </FormItem>

                                        <FormItem
                                          className="mt-4"
                                            label="Postcode"
                                        >
                                          <Input className="bg-gray-100" name="delivery[postcode]" value={(data && data.delivery_postcode) ? data.delivery_postcode : ''} readOnly={true} />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-12">
                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">
                                          Collection Information:
                                        </h2>
                                        <FormItem
                                            className="mt-4"
                                            label="Mobile No. / Landline No. *"
                                            desc="Enter your UK number (e.g., 07123456789, 02123456789). Must be 11 digits, starting with 01, 02, 03, or 07."
                                        >
                                            <Input badge="+44" onChange={numberCheck} name="collect[phone]" max="99999999999" data-valid="11" placeholder="Enter your UK number" type="number" required={true} className="isReq" />
                                        </FormItem>

                                        <FormItem
                                            className="mt-4"
                                            label="Onsite Telephone"
                                        >
                                            <Input badge="+44" onChange={numberCheck} name="collect[telephone]" max="99999999999" data-valid="11" placeholder="On-Site Contact Telephone" type="number" />
                                        </FormItem>
                                        
                                        <FormItem
                                            className="mt-4"
                                            label="Contact Name"
                                        >
                                            <Input name="collect[name]" placeholder="On-Site Contact Name" type="text"/>
                                        </FormItem>
                                        
                                        <FormItem
                                            className="mt-4"
                                            label="Collection Driver Notes:"
                                            desc="Avoid timed requests"
                                        >
                                            <Textarea name="collect[note]" placeholder="Notes are for driver only, not monitored by support Include access restrictions; no time-sensitive requests.For small vehicle, tick below."></Textarea>
                                        </FormItem>

                                        <div className="flex justify-between item-center mt-4">
                                          <FormItem
                                              label="Request Small Vehicle"
                                          >
                                            <Checkbox
                                                type="checkbox"
                                                label="Yes, I want small vehicle"
                                                nameId={"collect_small_vehicle"}
                                                name="collect[small_vehicle]"
                                                defaultChecked={false}
                                                className="text-sm font-medium"
                                            />
                                          </FormItem>
      
                                          <button type="button" onClick={() => setOpen(true)} className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 tnc-Button relative inline-flex items-center justify-center rounded-full transition-colors px-6 h-11 text-sm sm:text-base font-samll"> Explain </button>
                                        </div>

                                    </div>

                                    <div className="listingSection__wrap_x">
                                        <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">
                                          Delivery Information:
                                        </h2>
                                        <FormItem
                                            className="mt-4"
                                            label="Mobile No. / Landline No. *"
                                            desc="Enter your UK number (e.g., 07123456789, 02123456789). Must be 11 digits, starting with 01, 02, 03, or 07."
                                        >
                                            <Input badge="+44" onChange={numberCheck} name="delivery[phone]" max="99999999999" data-valid="11" placeholder="Enter your UK number" type="number" required={true} className="isReq" />
                                        </FormItem>
                                        
                                        <FormItem
                                            className="mt-4"
                                            label="Onsite Telephone"
                                        >
                                            <Input badge="+44" onChange={numberCheck} name="delivery[telephone]" max="99999999999" data-valid="11" placeholder="On-Site Contact Telephone" type="number" />
                                        </FormItem>
                                        
                                        <FormItem
                                            className="mt-4"
                                            label="Contact Name"
                                        >
                                            <Input name="delivery[name]" placeholder="On-Site Contact Name" type="text"/>
                                        </FormItem>
                                        
                                        <FormItem
                                            className="mt-4"
                                            label="Delivery Driver Notes:"
                                            desc="Avoid timed requests"
                                        >
                                            <Textarea name="delivery[note]" placeholder="Notes are for driver only, not monitored by support Include access restrictions; no time-sensitive requests.For small vehicle, tick below."></Textarea>
                                        </FormItem>

                                        <div className="flex justify-between item-center mt-4">
                                          <FormItem
                                              label="Request Small Vehicle"
                                          >
                                            <Checkbox
                                                type="checkbox"
                                                label="Yes, I want small vehicle"
                                                nameId={"delivery_small_vehicle"}
                                                name="delivery[small_vehicle]"
                                                defaultChecked={false}
                                                className="text-sm font-medium"
                                            />
                                          </FormItem>

                                          <button type="button" onClick={() => setOpen(true)} className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 tnc-Button relative inline-flex items-center justify-center rounded-full transition-colors px-6 h-11 text-sm sm:text-base font-samll"> Explain </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-2 grid grid-cols-1 sm:grid-cols-1 gap-12">
                                  <div className="listingSection__wrap_x">
                                    <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">Billing Information</h2>
                                    <FormItem
                                        label="Set your billing address save as"
                                        className=" mb-2"
                                    >
                                      <div className="flex justify-start mt-2">
                                        <div className="">
                                          <Checkbox
                                              type="radio"
                                              label="Collection"
                                              nameId={"billAddress_collect"}
                                              name="billing_same_as"
                                              defaultChecked={true}
                                              value="collect"
                                              className=" text-sm font-medium"
                                              onChange={() => setBillingAsCustom(false)}
                                          />
                                        </div>
                                        
                                        <div className="ml-5">
                                        <Checkbox
                                            type="radio"
                                            label="Delivery"
                                            nameId={"billAddress_delivery"}
                                            name="billing_same_as"
                                            defaultChecked={false}
                                            value="delivery"
                                            className="text-sm font-medium"
                                            onChange={() => setBillingAsCustom(false)}
                                        />
                                        </div>
                                        
                                        <div className="ml-5">
                                        <Checkbox
                                            type="radio"
                                            label="Custom"
                                            nameId={"billAddress_custom"}
                                            name="billing_same_as"
                                            defaultChecked={false}
                                            value="custom"
                                            className="text-sm font-medium"
                                            onChange={() => setBillingAsCustom(true)}
                                        />
                                        </div>
                                      </div>
                                    </FormItem>

                                    {(billingAsCustom) ? (

                                      <>
                                        <h2 className="text-1xl font-semibold mb-1 mt-5">Enter your custom billing address</h2>

                                        <div className="mt-2 grid grid-cols-3 sm:grid-cols-3 gap-3">
                                          <FormItem
                                            className="mt-2"
                                            label="Address Line 1"
                                          >
                                              <Input name="bill[address_line_1]" placeholder="Company Name or Adress line 1" type="text"/>
                                          </FormItem>

                                          <FormItem
                                            className="mt-2"
                                            label="Address Line 2"
                                          >
                                              <Input name="bill[address_line_2]" placeholder="Company Name or Adress line 2" type="text"/>
                                          </FormItem>

                                          <FormItem
                                            className="mt-2"
                                            label="Address Line 3"
                                          >
                                              <Input name="bill[address_line_3]" placeholder="Company Name or Adress line 3" type="text"/>
                                          </FormItem>
                                          
                                          <FormItem
                                            className="mt-2"
                                            label="Town"
                                          >
                                              <Input name="bill[town]" placeholder="City/town" type="text"/>
                                          </FormItem>

                                          <FormItem
                                            className="mt-2"
                                            label="Country"
                                          >
                                              <Input name="bill[county]" placeholder="County" type="text"/>
                                          </FormItem>
                                        </div>
                                      </>
                                    
                                    ) : ('')}
                                  </div>
                                </div>
                              </>
                            ) : ('')}

                            <div className="mt-2 grid grid-cols-1 sm:grid-cols-1 gap-12">
                              <div className="listingSection__wrap_x">
                                <h2 className="text-2xl font-semibold mb-3 mt-5 border-b py-3 border-t mb-5">Checkout Summary</h2>
                                  
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                  Dates & PostCode
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                  Pallets
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                  Description
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-right">
                                                  Units
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-right">
                                                  Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {pallet && pallet.length > 0 ? (
                                            pallet.map((item:any, index:number) => (
                                              <tr key={99+index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {(item.type == 'collect') ? (
                                                      <>
                                                        Collection - {item.postcode} <br />
                                                        {data && data.collection_date ? moment(data.collection_date).format('Do MMM YYYY') : null}
                                                      </>
                                                    ) : (
                                                      <>
                                                        Delivery - {item.postcode} <br />
                                                        {data && data.delivery_date ? moment(data.delivery_date).format('Do MMM YYYY') : null}
                                                      </>
                                                    )}
                                                  </th>
                                                  <td className="px-6 py-4">
                                                    {item.name} Pallet
                                                  </td>
                                                  <td className="px-6 py-4">
                                                    {item.detail.description_line1} <br />
                                                    {item.detail.description_line2}
                                                  </td>
                                                  <td className="px-6 py-4 text-right">
                                                    x {item.qty}
                                                  </td>
                                                  <td className="px-6 py-4 text-right font-normal">
                                                    £{item.price}
                                                  </td>
                                              </tr>
                                            ))
                                          ) : (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                              <td className="px-6 py-4 text-center" colSpan={5}> No Pallet Found </td>
                                            </tr>
                                          )}

                                          {data && data.delivery_services && data.delivery_services.delivery_option_id ? (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                  Delivery Service
                                                </th>
                                                <td className="px-6 py-4">
                                                  {data.delivery_services.delivery_option_name ?? null}
                                                </td>
                                                <td className="px-6 py-4">
                                                  {data.delivery_services.delivery_option_description ?? null}
                                                </td>
                                                <td className="px-6 py-4 text-right">
                                                </td>
                                                <td className="px-6 py-4 text-right font-normal">
                                                  £{data.delivery_services.delivery_option_price ?? null}
                                                </td>
                                            </tr>
                                          ) : ('')}

                                          {data && data.optionals && data.optionals.length > 0 ? (
                                            data.optionals.map((item:any, index:number) => (
                                              <tr key={199+index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                   {item.collection_option_name} <i className="text-xs text-gray-900 font-normal">Optional</i>
                                                  </th>
                                                  <td className="px-6 py-4" colSpan={3}>
                                                    {item.collection_option_description}
                                                  </td>
                                                  <td className="px-6 py-4 text-right font-normal">
                                                    £{item.collection_option_price}
                                                  </td>
                                              </tr>
                                            ))
                                          ) : ('')}

                                          {data && data.discount_price && data.discount_price > 0 ? (
                                            <tr>
                                              <th scope="col" colSpan={4} className=" py-3 text-right">
                                                Discount Applied ({setting && setting.discount ? setting.discount : 0}% Off)
                                              </th>
                                              <th scope="col" className="px-6 py-3 text-right text-red-800 font-normal">
                                                -£{data.discount_price}
                                              </th>
                                            </tr>
                                          ) : ('')}

                                          {data && data.vat && data.vat > 0 ? (
                                            <tr>
                                              <th scope="col" colSpan={4} className=" py-3 text-right">
                                                Vat
                                              </th>
                                              <th scope="col" className="px-6 py-3 text-right font-normal">
                                                +£{data.vat.toFixed(2)}
                                              </th>
                                            </tr>
                                          ) : ('')}

                                          {data && data.total && data.total > 0 ? (
                                            <tr>
                                              <th scope="col" colSpan={4} className=" py-3 text-right">
                                                Total
                                              </th>
                                              <th scope="col" className="px-6 py-3 text-right">
                                                +£{data.total.toFixed(2)}
                                              </th>
                                            </tr>
                                          ) : ('')}
                                        </tbody>
                                    </table>
                                </div>
                              </div>
                            </div>
                            
                            {(!proc) ? (
                              <div className="flex justify-center mt-20">
                                <div className="listingSection__wrap_x w-2/3">
                                  <p className="text-1xl font-semibold mb-3 mt-5">Fill all required fields with an orange border to continue.</p>
                                  
                                  <FormItem>
                                      <Checkbox 
                                        type="checkbox"
                                        label='By clicking on "Continue CheckOut", you accept the Terms of Use, confirm that you will abide by the Safety Tips, and declare that this posting does not include any Prohibited Items.'
                                        nameId={'accept'}
                                        defaultChecked={false}
                                        className="text-sm font-medium" 
                                        name="accept"
                                        required={true}
                                      />
                                  </FormItem>

                                  <button type="submit" className="w-full mt-5 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 tnc-Button relative inline-flex items-center justify-center rounded-full transition-colors px-6 h-11 text-sm sm:text-base font-samll"> 
                                    <span className="mr-3">Continue CheckOut</span> 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : ('')}
                          </form>

                        </main>
                    </div>
                </div>

                <div className="container py-6 mb-10">
                    {/* <SectionSubscribe2 className="pt-24" /> */}
                </div>
            </div>

            <Dialog open={open} onClose={setOpen} className="relative z-10">
              <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
              />
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 lg:max-w-3xl"
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <div className="flex justify-between items-center  mb-4">
                            <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                              SMALL VEHICLE EXPLAINED
                            </DialogTitle>
                            <span onClick={() => setOpen(false)} className="cursor-pointer"> 
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                              </svg>
                            </span>
                          </div>
                          <div className="flex justify-between">
                              <div className="flex items-center justify-center max-w-[280px]" style={{flex:"0 0 200px"}}>
                                <img src={`images/truck.png`} className="" />
                              </div>
                              <div>
                                <div className="mt-2">
                                  <h3 className="text-large font-semibold leading-6 text-gray-900">When we mention a 'small vehicle', we typically refer to a 7.5 tonne truck.</h3>  
                                  <p className="text-sm text-gray-500">
                                  Unsure if you need a small vehicle? A good rule of thumb is to consider if a rubbish collection lorry struggles with access to your location - our larger vehicles will face the same challenge. To prevent any difficulties, it's advisable to opt for this complimentary service when you suspect it might be needed.

                                  Please bear in mind, though we strive to deliver top-notch service, the availability of small vehicles within pallet networks is limited. This can occasionally impact the delivery dates if a small vehicle isn't available on your chosen day.
                                  </p>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
        </>
    );
};

export default CheckOutAddress;
