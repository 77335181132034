"use client";

import { CubeIcon} from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import $ from 'jquery';
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import ButtonSubmit from "../ButtonSubmit";
import { usePallets, Pallet } from "hooks/Products";
import { ExclamationTriangleIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";

export interface PalletSelectionInputProps {
    placeHolder?: string;
    desc?: string;
    className?: string;
    divHideVerticalLineClass?: string;
    autoFocus?: boolean;
    mobile? : boolean;
    updateParent?: (value: any) => void;
}

const PalletSelectionInput: FC<PalletSelectionInputProps> = ({
    autoFocus = false,
    placeHolder = "Location",
    desc = "Where are you going?",
    className = "nc-flex-1.5",
    updateParent,
    mobile = false,
}) => {
    
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState("");
    const [showPopover, setShowPopover] = useState(autoFocus);

    useEffect(() => {
        setShowPopover(autoFocus);
    }, [autoFocus]);

    useEffect(() => {
        if (eventClickOutsideDiv) {
            document.removeEventListener("click", eventClickOutsideDiv);
        }
        showPopover && document.addEventListener("click", eventClickOutsideDiv);
        return () => {
            document.removeEventListener("click", eventClickOutsideDiv);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopover]);

    useEffect(() => {
        if (showPopover && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showPopover]);

    const eventClickOutsideDiv = (event: MouseEvent) => {
        // if (!containerRef.current) return;
        // // CLICK IN_SIDE
        // if (
        //     !showPopover ||
        //     containerRef.current.contains(event.target as Node)
        // ) {
        //     return;
        // }
        // // CLICK OUT_SIDE
        // setShowPopover(false);
    };

    const { pallets, loading, error } = usePallets();

    const [StandardPallets, setStandardPallets] = useState<Pallet[]>([]);
    const [OversizePallets, setOversizePallets] = useState<Pallet[]>([]);

    useEffect(() => {
        if (!loading && !error) {
            setStandardPallets(pallets.filter(p => !p.oversize));
            setOversizePallets(pallets.filter(p => p.oversize));
        }
    }, [pallets, loading, error]);

    useEffect(() => {
        const sp:any = StandardPallets
            .filter((item: any) => item.qty > 0)
            .map((item: any) => item.qty+' x '+item.api_product_code);

        const sqp:any = OversizePallets
            .filter((item: any) => item.qty > 0)
            .map((item: any) => item.qty+' x '+item.api_product_code);

        var mergedArray = sp.concat(sqp).join(', ');
        setValue(mergedArray)
        updateParent?.(mergedArray);

    }, [StandardPallets, OversizePallets]);

    const updatePallet = (id: number) => {
        setStandardPallets((prevPallets:any) =>
            prevPallets.map((item:any) =>
                item.product_id === id
                    ? { ...item, qty: (item.qty || 0) + 1 }
                    : item
            )
        );
        setOversizePallets((prevPallets:any) =>
            prevPallets.map((item:any) =>
                item.product_id === id
                    ? { ...item, qty: (item.qty || 0) + 1 }
                    : item
            )
        );

    };

    const removePallet = (id: number) => {
        setStandardPallets((prevPallets:any) =>
            prevPallets.map((item:any) =>
                item.product_id === id
                    ? { ...item, qty: Math.max((item.qty || 0) - 1, 0) }
                    : item
            )
        );
        setOversizePallets((prevPallets:any) =>
            prevPallets.map((item:any) =>
                item.product_id === id
                    ? { ...item, qty: Math.max((item.qty || 0) - 1, 0) }
                    : item
            )
        );
    };
    

    const [activeTab, setActiveTab] = useState('standard'); 

    const handleTabClick = (tab:any) => {
        setActiveTab(tab); 
    };


    const palletsDetails = () => {
        return (
            <>
                {/* Tab Headers */}
                <ul className="w-full flex justify-center border-b border-gray-300">
                    <li className={`w-full text-center cursor-pointer py-2 ${activeTab === 'standard' ? 'text-blue-600 border-b-4 border-blue-600' : 'text-gray-600'}`} 
                        onClick={() => handleTabClick('standard')}>
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-2">
                            Sending Standard Pallets?
                        </DialogTitle>
                    </li>
                    <li className={`w-full text-center cursor-pointer py-2 ${activeTab === 'oversize' ? 'text-blue-600 border-b-4 border-blue-600' : 'text-gray-600'}`} 
                        onClick={() => handleTabClick('oversize')}>
                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-2">
                            Sending Oversize Pallets?
                        </DialogTitle>
                    </li>
                </ul>

                {/* Tab Contents */}
                <div className="mt-4">
                    {activeTab == 'standard' && (
                    <div  className="mt-2 grid grid-cols-2 sm:grid-cols-2 gap-3">
                        {StandardPallets && Array.isArray(StandardPallets) && StandardPallets.length > 0 ? (
                            StandardPallets.map((item) => (
                                <div key={item.product_id} className="border border-gray-300 rounded-lg p-2 flex flex-col items-center">
                                    <div className="flex justify-start w-full">
                                        <div className="flex justify-center items-center bg-neutral-100 p-2 w-36">
                                            <img
                                                src={`/images/${(item.product_name) ? item.product_name.replace(' Oversize','').toLowerCase() : ''}-pallet.png`}
                                                alt="Mini Pallet"
                                                className="w-20 h-20 object-contain"
                                            />
                                        </div>
                                        <div className="w-full px-4">
                                            <div className="flex flex-col justify-center">
                                                <h3 className="text-lg font-semibold">
                                                    {item.product_name} Pallets
                                                </h3>
                                                <p className="text-sm text-gray-500">
                                                    {item.description_line1}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {item.description_line2}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-between w-full mt-2">
                                                <div className="text-sm text-blue-700 bg-blue-100 px-2 py-1 rounded-full">
                                                    Up to {item.weight}kg
                                                </div>
                                                <div className="bg-gray-100 border-1 border-gray-200 flex items-center p-1 palletButtonBox rounded-full">
                                                    <input type="hidden" name={`product[${item.api_product_code}]`}  value={item.qty ? item.qty : 0} data-code={item.api_product_code} />
                                                    <MinusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={() => removePallet(item.product_id)} />
                                                    <span className="px-4 numCount">{item.qty ? item.qty : 0}</span>
                                                    <PlusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={() => updatePallet(item.product_id)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            ))
                        ) : (
                            <p>No pallets available</p> // Optional: display a message when there are no items
                        )}
                    </div>
                    )}
                    {activeTab == 'oversize' && (
                    <div className="mt-2 grid grid-cols-2 sm:grid-cols-2 gap-3">
                        {OversizePallets && Array.isArray(OversizePallets) && OversizePallets.length > 0 ? (
                            OversizePallets.map((item) => (
                                <div key={item.product_id} className="border border-gray-300 rounded-lg p-2 flex flex-col items-center">
                                    <div className="flex justify-start w-full">
                                        <div className="flex justify-center items-center bg-neutral-100 p-2 w-36">
                                            <img
                                                src={`/images/${item.product_name.replace(' Oversize','').toLowerCase()}-pallet.png`}
                                                alt="Mini Pallet"
                                                className="w-20 h-20 object-contain"
                                            />
                                        </div>
                                        <div className="w-full px-4">
                                            <div className="flex flex-col justify-center">
                                                <h3 className="text-lg font-semibold">
                                                    {item.product_name} Pallets
                                                </h3>
                                                <p className="text-sm text-gray-500">
                                                    {item.description_line1}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {item.description_line2}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-between w-full mt-2">
                                                <div className="text-sm text-blue-700 bg-blue-100 px-2 py-1 rounded-full">
                                                    Up to {item.weight}kg
                                                </div>
                                                <div className="bg-gray-100 border-1 border-gray-200 flex items-center p-1 palletButtonBox rounded-full">
                                                    <input type="hidden" name={`product[${item.api_product_code}]`}  value={item.qty ? item.qty : 0} data-code={item.api_product_code} />
                                                    <MinusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={removePallet.bind(this, item.product_id)} />
                                                    <span className="px-4 numCount">{item.qty ? item.qty : 0}</span>
                                                    <PlusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={updatePallet.bind(this, item.product_id)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No pallets available</p> // Optional: display a message when there are no items
                        )}
                    </div>
                    )}
                </div>
            </>
        );
    };
    
    const palletsMobileDetails = () => {
        return (
            <>
                {/* Tab Headers */}
                <div className="relative">
                <span className="ml-3 text-red-600 top-0 absolute" style={{ overflow:'hidden', padding:'0px 30px',right: '0', top: '-58px', width: '40px', height: '40px', background: 'rgb(45 154 210)', borderRadius: '100rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color:'white'}} onClick={() => setShowPopover(false)}>
                    Done
                </span>
                </div>
                <ul className="w-full flex justify-center border-b border-gray-300">
                    <li className={`w-full text-center cursor-pointer py-2 ${activeTab === 'standard' ? 'text-blue-600 border-b border-blue-600' : 'text-gray-600'}`} 
                        onClick={() => handleTabClick('standard')}>
                        <h5 className="font-semibold leading-6 text-gray-900 text-sm">
                            Standard Pallets?
                        </h5>
                    </li>
                    <li className={`w-full text-center cursor-pointer py-2 ${activeTab === 'oversize' ? 'text-blue-600 border-b border-blue-600' : 'text-gray-600'}`} 
                        onClick={() => handleTabClick('oversize')}>
                        <h5 className="font-semibold leading-6 text-gray-900 text-sm">
                            Oversize Pallets?
                        </h5>
                    </li>
                </ul>

                {/* Tab Contents */}
                <div className="mt-4" style={{ overflow:'scroll', padding:'0px 10px'}} >
                    {activeTab == 'standard' && (
                    <div  className="mt-2 grid grid-cols-1 sm:grid-cols-1 gap-1">
                        {StandardPallets && Array.isArray(StandardPallets) && StandardPallets.length > 0 ? (
                            StandardPallets.map((item) => (
                                <div key={item.product_id} className="border border-gray-300 rounded-lg p-2 flex flex-col items-center">
                                    <div className="flex justify-start w-full">
                                        <div className="flex justify-center items-center bg-neutral-100 p-2 w-24">
                                            <img
                                                src={`/images/${(item.product_name) ? item.product_name.replace(' Oversize','').toLowerCase() : ''}-pallet.png`}
                                                alt="Mini Pallet"
                                                className="w-10 h-10 object-contain"
                                            />
                                        </div>
                                        <div className="w-full px-4">
                                            <div className="flex flex-col justify-center">
                                                <h3 className="text-sm font-semibold">
                                                    {item.product_name} Pallets
                                                </h3>
                                                <p className="text-xs text-gray-500">
                                                    {item.description_line1}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                    {item.description_line2}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-between w-full ">
                                                <div className="text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded-full">
                                                    Max: {item.weight}kg
                                                </div>
                                                <div className="bg-gray-100 text-sm border-1 border-gray-200 flex items-center p-1 palletButtonBox rounded-full">
                                                    <input type="hidden" name={`product[${item.api_product_code}]`}  value={item.qty ? item.qty : 0} data-code={item.api_product_code} />
                                                    <MinusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={() => removePallet(item.product_id)} />
                                                    <span className="px-4 numCount">{item.qty ? item.qty : 0}</span>
                                                    <PlusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={() => updatePallet(item.product_id)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            ))
                        ) : (
                            <p>No pallets available</p> // Optional: display a message when there are no items
                        )}
                    </div>
                    )}
                    {activeTab == 'oversize' && (
                    <div className="mt-2 grid grid-cols-1 sm:grid-cols-1 gap-1">
                        {OversizePallets && Array.isArray(OversizePallets) && OversizePallets.length > 0 ? (
                            OversizePallets.map((item) => (
                                <div key={item.product_id} className="border border-gray-300 rounded-lg p-2 flex flex-col items-center">
                                    <div className="flex justify-start w-full">
                                        <div className="flex justify-center items-center bg-neutral-100 p-2 w-24">
                                            <img
                                                src={`/images/${item.product_name.replace(' Oversize','').toLowerCase()}-pallet.png`}
                                                alt="Mini Pallet"
                                                className="w-10 h-10 object-contain"
                                            />
                                        </div>
                                        <div className="w-full px-4">
                                            <div className="flex flex-col justify-center">
                                                <h3 className="text-sm font-semibold">
                                                    {item.product_name} Pallets
                                                </h3>
                                                <p className="text-xs text-gray-500">
                                                    {item.description_line1}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                    {item.description_line2}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div className="text-xs text-blue-700 bg-blue-100 px-2 py-1 rounded-full">
                                                    Max: {item.weight}kg
                                                </div>
                                                <div className="bg-gray-100 text-sm border-1 border-gray-200 flex items-center p-1 palletButtonBox rounded-full">
                                                    <input type="hidden" name={`product[${item.api_product_code}]`}  value={item.qty ? item.qty : 0} data-code={item.api_product_code} />
                                                    <MinusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={removePallet.bind(this, item.product_id)} />
                                                    <span className="px-4 numCount">{item.qty ? item.qty : 0}</span>
                                                    <PlusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-7 lg:h-7" onClick={updatePallet.bind(this, item.product_id)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No pallets available</p> // Optional: display a message when there are no items
                        )}
                    </div>
                    )}
                </div>
            </>
        );
    };

    if(mobile){
        return (
            <>
            <div className={`border-t pt-3 flex ${className}`} ref={containerRef} >
                <div className={`flex z-10 flex-1 relative flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`} onClick={() => (showPopover == true) ? setShowPopover(false) : setShowPopover(true)}>
                    <div className="text-neutral-300 dark:text-neutral-400">
                        <CubeIcon className="w-5 h-5 lg:w-7 lg:h-7 ml-4" />
                    </div>
                    <div className="flex-grow cursur-pointer">
                        <input
                            className={`cursur-pointer block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                            placeholder={placeHolder}
                            value={value}
                            autoFocus={showPopover}
                            onChange={(e) => {
                                setValue(e.currentTarget.value);
                            }}
                            readOnly
                            ref={inputRef}
                        />
                        <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                            <span className="line-clamp-1">
                                {!!value ? placeHolder : desc}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="pr-2 xl:pr-4">
                    {!showPopover && 
                    <button type="submit" className="text-sm h-10 w-10 rounded-full flex items-center justify-center  focus:outline-none" style={{background:'#0b9ad1', width:'100px', color:'white'}}>
                        Get a Quote
                    </button>
                    }
                </div>
            </div>

            {showPopover && 
                <div className="h-auto left-0 top-0 w-full z-50">
                    <div className="bg-white px-2 pb-4 pt-3 sm:px-4 sm:py-2 sm:pb-2">
                        {palletsMobileDetails()}
                    </div>  
                </div>
            }
            </>
    );
    }else{
        return (
            <>
                <Dialog open={showPopover} onClose={setShowPopover} className="relative z-10">
                    <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="bg-white px-4 pb-4 pt-4 sm:px-6 sm:py-4 sm:pb-4">
                                    {palletsDetails()}
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        onClick={() => setShowPopover(false)}
                                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    >
                                        Save
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

                <div className={`flex ${className}`} ref={containerRef}>
                    <div className={`flex z-10 flex-1 relative flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`}>
                        <div className="text-neutral-300 dark:text-neutral-400">
                            <CubeIcon className="w-5 h-5 lg:w-7 lg:h-7 ml-5" />
                        </div>
                        <div className="flex-grow cursur-pointer" onClick={() => setShowPopover(true)}>
                            <input
                                className={`cursur-pointer block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                                placeholder={placeHolder}
                                value={value}
                                autoFocus={showPopover}
                                onChange={(e) => {
                                    setValue(e.currentTarget.value);
                                }}
                                readOnly
                                ref={inputRef}
                            />
                            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                                <span className="line-clamp-1">
                                    {!!value ? placeHolder : desc}
                                </span>
                            </span>
                        </div>
                        <div className="pr-2 xl:pr-4">
                            {/* <ButtonSubmit href="javascript:void(0);" /> */}
                            <button className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none">
                                <span className="mr-3 md:hidden">Search</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                        {palletsDetails()}
                    </div> */}

                </div>
            </>

        );
    }

};

export default PalletSelectionInput;
