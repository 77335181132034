import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";

// Components
import Footer from "components/Footer";
import Header from "components/Header";
// import useWindowSize from "hooks/useWindowResize";

// PAGES
import PageHome from "containers/PageHome";
import Page404 from "containers/Page404";
import CheckOutPage from "containers/CheckOutPage";
import CheckOutAddress from "containers/CheckOutAddress";
import ThankyouPage from "containers/ThankyouPage";
import TrackingPage from "containers/TrackingPage";
import WhyUsPage from "containers/WhyUsPage";
import ContactUsPage from "containers/ContactUsPage";
import TermsOfUsePage from "containers/TermsOfUsePage";
import useWindowSize from "hooks/useWindowResize";
import FooterNav from "components/FooterNav";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/checkout", exact: true, component: CheckOutPage },
  { path: "/checkout-address", exact: true, component: CheckOutAddress },
  { path: "/thankyou", exact: true, component: ThankyouPage },
  { path: "/tracking/:tracking", exact: true, component: TrackingPage },
  { path: "/why-us", exact: true, component: WhyUsPage },
  { path: "/contact-us", exact: true, component: ContactUsPage },
  { path: "/terms-of-use", exact: true, component: TermsOfUsePage },

  { path: "*", exact: true, component: Page404 }
  
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <ToastContainer />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />

      <a className="whatsapp-widget" target="_blank" href="https://api.whatsapp.com/send/?phone=447375798890&amp;text&amp;type=phone_number&amp;app_absent=0" rel="noopener" style={{position:'fixed',right:'13px',bottom:'15px',zIndex:29}} >
        <img src="https://palletbooking.co.uk/images/whatsapp.png" alt="img" style={{width:'50px'}} />
      </a>
    </BrowserRouter>
  );
};

export default MyRoutes;
