import React from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MobileHeader from "shared/Heading/MobileHeader";
import { useLocation } from "react-router-dom";


function Header() {

  const location = useLocation();
  const isHomePage = location.pathname === "/";

    return (
        <div className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg`}>
          <div className="nc-MainNav1 relative z-10 border-b shadow-sm">
            <div className="px-4 lg:container py-3 lg:py-3 relative flex justify-between items-center">
              <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
                <Logo />
                <Navigation />
              </div>

              <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
                <div className="md:hidden flex justify-center mb-3">
                  <Logo />
                </div>
                {isHomePage && <MobileHeader />}
              </div>

              <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
                <div className="hidden xl:flex items-center space-x-0.5">
                  {/* <SwitchDarkMode /> */}
                  {/* <SearchDropdown /> */}
                  <div className="px-1" />
                    <ButtonPrimary href="/tracking/Your-Tracking-code-here">Track my Pallet</ButtonPrimary>
                  </div>
                <div className="flex xl:hidden items-center">
                  {/* <SwitchDarkMode /> */}
                  <div className="px-0.5" />
                  {/* <MenuBar /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default Header;